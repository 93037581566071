
export interface AddRequestObject {
    cell_model_id: number | "";
    request_group_id: number | "";
    name: string;
    quantity: number;
    requesting_user_id: number | "";
    scan_type_id: number | "";
    upload_post_processed: boolean;
    upload_projections: boolean;
    upload_recon: boolean;
    data: null, // skipping data for now for simplicity
}


export interface AddScanTypeObject {
    description: string,
    field_of_view: string,
    name: string,
    nominal_cycle_time_s: number,
    nominal_scan_time_s: number,
    scan_metadata: null, // skipping for now
    updated: null,
    voxel_size_mm: number,
    data: { VoxelsX: number, VoxelsY: number, VoxelsZ: number }
}


export interface AddCellModelObject {
    axes: string,
    // TODO: move to own columns
    data: {
        can_diameter_mm: number,
        can_height_mm: number,
    } | {
        can_depth_mm: number,
        can_height_mm: number,
        can_width_mm: number,
    },
    form_factor: string,
    name: string,
    updated: null,
    vendor: string,
}

export interface AddRequestGroupObject {
    is_active: boolean,
    name: string,
    created: null,
    updated: null,
    data: null,
    is_public: boolean,
}

export interface AddMembershipObject {
    user_id: number,
    request_group_id: number,
    user_permissions: UserPermissionOptions,
    created: null,
    data: null,
}

export interface AddUserObject {
    user_name: string,
    email: string,
    auth0_id: string,
    company_id: number,
    created: null,
    is_active: boolean,
    data: null,
}

export interface AddCompanyObject {
    name: string,
    legal_name: string,
    domain: string,
    is_active: boolean,
    data: null,
    require_tos_signing: boolean
}

export enum InternalPageMenuOptions {
    REQUESTS = "Requests",
    SCAN_TYPES = "Scan Types",
    CELL_MODELS = "Cell Models",
    REQUEST_GROUPS = "Request Groups",
    REQUEST_GROUP_MEMBERSHIP = "Request Group Membership",
    USERS = "Users",
    SCANNER = "Scanner",
    SCANS = "Scans",
    COMPANIES = "Companies",
    TOS_RECORDS = "TOS Records",
    GLIMPSEBOXES = "Glimpseboxes",
}

/**
* @description - For interfacing with the database, these are the enums present in db/models.py
*/
export enum UserPermissionOptions {
    READONLY = "RO",
    READWRITE = "RW",
    ADMIN = "admin"
}

/**
* @description - For interfacing with the database, confusingly these are different than what's in thh db/models.py
* TODO: check on this
*/
export enum FormFactorOptions {
    _18650 = "18650",
    _2170 = "2170",
    _4680 = "4680",
    POUCH = "pouch",
    PRISMATIC = "prismatic",
}

export enum FieldOfViewOptions {
    FULL = "full",
    CORNER = "corner",
    TOP = "top",
    BOTTOM = "bottom",
    LEFT = "left",
    RIGHT = "right",
    FRONT = "front",
    BACK = "back",
}
