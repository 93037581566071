import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import { OperatorGlimpseboxList, UserAccessState } from '../../types';
import { appTopNavBarHeight } from '../../utils';
import { ApiEndpoints } from '../../utils/apiUtils';
import BatchSelectionTool from './BatchSelectionTool';
import LogOfScans from './LogOfScans';
import OperatorNav, { OperatorPageOptions } from './OperatorNav';
import SerialNumberRenamingTool from './SerialNumberRenamingTool';


export default function OperatorPage(
  { userAccessState }: { userAccessState: UserAccessState }
) {
  const [operatorPageType, setOperatorPageType] = useState(OperatorPageOptions.LogOfScans);
  const { fetchData } = useAuthTokenAndAccessApi()
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [glimpseboxes, setGlimpseboxes] = useState<OperatorGlimpseboxList[]>([]);
  const [selectedGlimpsebox, setSelectedGlimpsebox] = useState<OperatorGlimpseboxList | null>(null);

  useEffect(() => { getGlimpseboxes() }, []);

  const getGlimpseboxes = async () => {
    const response = await fetchData(ApiEndpoints.OPERATOR_GLIMPSEBOXES);
    const data = response.data as OperatorGlimpseboxList[];
    setGlimpseboxes(data);
    setSelectedGlimpsebox(data[0]);
    setLoadingStatus(false);
  }

  const handleGlimpseboxChange = (glimpsebox: OperatorGlimpseboxList) => {
    setSelectedGlimpsebox(glimpsebox);
  }

  const displayManager = () => {
    if (loadingStatus || !selectedGlimpsebox) {
      return <Box>Loading...</Box>
    }
    switch (operatorPageType) {
      case OperatorPageOptions.LogOfScans:
        return <LogOfScans
          userAccessState={userAccessState}
          selectedGlimpsebox={selectedGlimpsebox}
          availableGlimpseboxes={glimpseboxes}
          handleGlimpseboxChange={handleGlimpseboxChange}
        />

      case OperatorPageOptions.SerialNumberRenamingTool:
        return <SerialNumberRenamingTool />
      case OperatorPageOptions.Glimpseboxes:
        return <BatchSelectionTool
          selectedGlimpsebox={selectedGlimpsebox}
          availableGlimpseboxes={glimpseboxes}
          handleGlimpseboxChange={handleGlimpseboxChange}
        />
      default: return <></>

    }
  }

  return (
    <>
      <Box sx={{ display: "flex", flexGrow: 1, height: `calc(100vh - ${appTopNavBarHeight}px)` }}>
        <OperatorNav
          operatorPageType={operatorPageType}
          setOperatorPageType={setOperatorPageType}
          userAccessState={userAccessState}
        />
        <Box sx={{
          flexGrow: 1,
          borderLeft: "1px solid #ccc",
          height: `calc(100vh - ${appTopNavBarHeight}px)`,
          overflow: 'auto',
          ml: 4,
          mt: 3
        }}>
          <Box sx={{ maxWidth: 1200, margin: "auto", ml: 4 }}>
            {displayManager()}
          </Box>
        </Box>
      </Box >
    </>
  );
}
