import { AutomatedInspectionMetrics, calculateMaxMin, colorAndHighlightMaxMin, StatsDisplayNames } from "."
import {
    AnnotationShapeList,
    AnnotationShapeOptions,
    RawCathodeTraceResults,
    RawElectrodeOverhangTraceResults
} from "../../types"


export const generateCathodeTraceShapes = (
    rawCathodeTraceResults: RawCathodeTraceResults[],
    visible_slice_id: number,
    highlightedStat: StatsDisplayNames | null
) => {
    const cathodeTraceAtSlice = rawCathodeTraceResults
        .filter((e) => e.slice_id === visible_slice_id)

    const maxMinCathodeTraceLength = calculateMaxMin(cathodeTraceAtSlice.map((e) => e.trace_length))
    const cathodeTrace = cathodeTraceAtSlice
        .map((e) => ({
            coordinates: e.shape_points,
            shapeType: AnnotationShapeOptions.LINE,
            color: colorAndHighlightMaxMin(e.trace_length, maxMinCathodeTraceLength, highlightedStat)
        } as AnnotationShapeList))
    return cathodeTrace
}

export const generateCathodePositionShapes = (
    rawCathodePositionResults: RawCathodeTraceResults[],
    visible_slice_id: number,
    location: "top" | "bottom",
    highlightedStat: StatsDisplayNames | null
) => {
    const cathodePositionAtSlice = rawCathodePositionResults.filter((e) => e.slice_id === visible_slice_id)
    const pointIndex = location === "top" ? 1 : 0
    const data = cathodePositionAtSlice.map((e) => location === "top" ? e.top_position : e.bottom_position)
    const minMaxCathodePosition = calculateMaxMin(data)
    const cathodePosition = cathodePositionAtSlice
        .map((e) => ({
            coordinates: [e.shape_points[pointIndex]],
            shapeType: AnnotationShapeOptions.POINTS,
            color: colorAndHighlightMaxMin(location === "top" ? e.top_position : e.bottom_position,
                minMaxCathodePosition, highlightedStat)
        } as AnnotationShapeList))
    return cathodePosition
}

export const generateElectrodeOverhangShapes = (
    RawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
    visible_slice_id: number,
    location: "top" | "bottom" | "all",
    highlightedStat: StatsDisplayNames | null
) => {
    const electrodeOverhangTraceAtSlice = location === "all" ?
        RawElectrodeOverhangTraceResults
            .filter((e) => e.slice_id === visible_slice_id) :
        RawElectrodeOverhangTraceResults
            .filter((e) => e.slice_id === visible_slice_id)
            .filter((e) => e.location === location)

    const minMaxElectrodeOverhangTrace = calculateMaxMin(electrodeOverhangTraceAtSlice.map((e) => e.trace_length))
    const electrodeOverhangTrace = electrodeOverhangTraceAtSlice
        .map((e) => ({
            coordinates: e.shape_points,
            shapeType: AnnotationShapeOptions.LINE,
            color: colorAndHighlightMaxMin(e.trace_length, minMaxElectrodeOverhangTrace, highlightedStat)
        } as AnnotationShapeList))
    return electrodeOverhangTrace
}

export const getShapesForAnodesAndCathodes = (
    visibleInspectionNames: string[],
    highlightedAutomaticInspection: { metric: AutomatedInspectionMetrics, stat?: StatsDisplayNames } | null,
    rawCathodeTraceResults: RawCathodeTraceResults[],
    RawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
    visible_slice_id: number
) => {
    let combined = [] as AnnotationShapeList[]
    const highlightedStat = highlightedAutomaticInspection?.stat
    const highlightedMetric = highlightedAutomaticInspection?.metric
    let stat = null

    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.CATHODE_WIDTH)) {
        if (highlightedMetric === AutomatedInspectionMetrics.CATHODE_WIDTH && highlightedStat) stat = highlightedStat
        combined.push(...generateCathodeTraceShapes(rawCathodeTraceResults, visible_slice_id, stat))
    }
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.CATHODE_POSITION_TOP)) {
        if (highlightedMetric === AutomatedInspectionMetrics.CATHODE_POSITION_TOP && highlightedStat) stat = highlightedStat
        combined.push(...generateCathodePositionShapes(rawCathodeTraceResults, visible_slice_id, "top", stat))
    }
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM)) {
        if (highlightedMetric === AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM && highlightedStat) stat = highlightedStat
        combined.push(...generateCathodePositionShapes(rawCathodeTraceResults, visible_slice_id, "bottom", stat))
    }
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL)) {
        if (highlightedMetric === AutomatedInspectionMetrics.ANODE_OVERHANG_ALL && highlightedStat) stat = highlightedStat
        combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "all", stat))
    }
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_TOP)) {
        if (highlightedMetric === AutomatedInspectionMetrics.ANODE_OVERHANG_TOP && highlightedStat) stat = highlightedStat
        combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "top", stat))
    }
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM)) {
        if (highlightedMetric === AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM && highlightedStat) stat = highlightedStat
        combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "bottom", stat))
    }
    return combined
}
