import { AutomatedInspectionMetrics } from "."
import { AnnotationShapeOptions, RawGeneralInspectionResults } from "../../types"
import { colorAnnotation } from "../colorScheme"


export const getCanWallThicknessShapes = (
    rawAutomatedInspectionResults: RawGeneralInspectionResults[],
    visibleInspectionNames: string[],
    isHighlighted: boolean,
) => {
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN)) {
        const canWallThickness = rawAutomatedInspectionResults
            .filter((e) => [
                AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
                AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN
            ].includes(e.metric_internal_name))
            .map((e) => ({
                coordinates: e.shape_points,
                shapeType: AnnotationShapeOptions.POINTS,
                color: colorAnnotation(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN, isHighlighted)
            }))
        return canWallThickness
    }
    return null
}


export const getMeanCrimpGrooveGapShapes = (
    rawAutomatedInspectionResults: RawGeneralInspectionResults[],
    visibleInspectionNames: string[],
    isHighlighted: boolean,
) => {
    if (visibleInspectionNames.includes(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP)) {
        const meanCrimpGrooveGap = rawAutomatedInspectionResults
            .filter((e) => [AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP, AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP].includes(e.metric_internal_name))
            .map((e) => ({
                coordinates: e.shape_points,
                shapeType: AnnotationShapeOptions.LINE,
                color: colorAnnotation(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP, isHighlighted)
            }))
        return meanCrimpGrooveGap
    }
    return null
}
