// Place for types and enums

import { AutomatedInspectionMetrics } from "./utils/inspection";

// TODO: could probably make this auto generated from SQLalchemy models? whatever though
export interface ScanList {
    cell_sn: string;
    file: string;
    post_processing_completed: Date;
    pipeline_completed: boolean;
    request_id: number;
    request_name: string;
    scan_id: number;
    cell_model_name: string;
    cell_model_vendor: string;
    scanner_vendor: string;
    metadata: ScanMetadata[];
}

export interface RequestInfo {
    post_processing_completed: Date;
    request_id: number;
    request_name: string;
    cell_model_name: string;
    cell_model_vendor: string;
    scanner_vendor: string;
    scan_count: number;
}

export interface SliceRow {
    slice_id: number;
    axes: ScanAxes;
    orientation: SliceOrientation;
    position: number;
    scan_id: number;
    voxel_size_mm: number;
}

export interface ScanMetadata {
    field: string;
    value: boolean | string | number;
}

export enum PageOptions {
    SCANS = 'scan',
    SLICES = 'slices',
    METROLOGY = 'metrology',
    FAQ = 'faq',
    USER = 'user',
    OPERATOR = 'operator',
    INTERNAL_READ = 'internal_read',
    INTERNAL_WRITE = 'internal_write',
    TERMS = 'tos',
}

export enum UserAccessState {
    LOADING = "loading",
    SIGNUP_INCOMPLETE = "signup_incomplete",
    SHOW_TERMS_PAGE = "show_terms_page",
    SIGNUP_COMPLETE = "signup_complete",
    GLIMPSE_INTERNAL_READ = "glimpse_internal_read",
    GLIMPSE_INTERNAL_WRITE = "glimpse_internal_write",
    EMAIL_UNVERIFIED = "email_unverified",
    CUSTOMER_OPERATOR = "operator_external",
    ERROR = "error",
}


export enum SliceOrientation {
    AXIAL = 'axial',
    RADIAL = 'radial',
    XY = 'xy',
    YZ = 'yz',
    XZ = 'xz',
}

export enum ScanAxes {
    CYL = 'cylindrical',
    XYZ = 'cartesian',
}

export interface SlicePositionsCyl {
    axial: number,
    radial: number
}
export interface SlicePositionsXyz {
    xy: number,
    xz: number,
    yz: number
}

export interface SliceImageObject {
    slice_id: number;
    image: string;
}

export enum ImageLoadingStatus {
    SERVER_COMPILING_ZIP = 'server_compiling_zip',
    DOWNLOADING_ZIP = 'downloading_zip',
    TOO_MANY_REQUESTS = 'too_many_requests',
    SUCCESS = 'success',
    ERROR = 'error',
    IDLE = 'idle',
}

export interface dimensions {
    height: number,
    width: number,
}

export interface LineState {
    id: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    length?: number;
}

export enum ManualMeasurementOptions {
    LINE = "line",
    POLYGON = "polygon",
    ANGLE = "angle",
    RADIUS = "radius",
    POLYLINE = "polyline",
}

export enum AnnotationShapeOptions {
    LINE = "line",
    POLYGON = "polygon",
    ANGLE = "angle",
    RADIUS = "radius",
    POINTS = "points",
    CIRCLE_AND_LINE = "circle_and_line",
    ELLIPSE_AND_LINES = "ellipse_and_lines",
    NONE = "none",
    POLYLINE = "polyline",
}
export interface AnnotationShapeList {
    id?: number; // only needed for object keys: apply at the end potential GOTCHA
    shapeType: AnnotationShapeOptions;
    coordinates: [number, number][] | number[];
    color: string;
}

export interface CommonSvgShapes {
    id: number;
    coordinates: [number, number][];
    color: string;
}


export interface PointGeometry {
    x: number;
    y: number;
}

export interface OrientationGuideLines {
    start: PointGeometry;
    end: PointGeometry;
    label: string;
}

export enum MeasurementStatus {
    COMPLETE,
    IN_PROGRESS,
    ANGLE_STARTED,
    ANGLE_VERTEXED,
}


export interface ManualMeasurementResults {
    id: number;
    result: number;
    shapeType: ManualMeasurementOptions;
    display: boolean;
    coordinates: PointGeometry[]; // TODO: could de-type this to be more aligned with automated inspection?
}

export interface AutomatedMeasurementsShapes {
    id: number;
    display: boolean;
    manual: boolean;
}


export interface RawGeneralInspectionResults {
    metric_id: number,
    slice_id: number,
    value: number,
    shape_points: [number, number][] | number[],
    metric_internal_name: AutomatedInspectionMetrics,
}

export interface RawCathodeTraceResults {
    slice_id: number,
    shape_points: [number, number][],
    trace_length: number,
    top_position: number,
    bottom_position: number,
}

export interface RawElectrodeOverhangTraceResults {
    slice_id: number,
    shape_points: [number, number][],
    location: string,
    trace_length: number,
}



export enum PostingApiStatus {
    SUCCESS,
    FALSE,
    LOADING,
    ERROR
}


export interface LineChartData {
    xLabel: string;
    tooltip: string;
    average: number;
    min: number;
    max: number
    id: number;
}


export interface OperatorGlimpseboxList {
    request_group_id: number;
    glimpsebox_user_id: number;
    email: string;
    user_permissions: string;
    data: {
        active_request_id: number;
    };
}
