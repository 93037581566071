import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import React, { useState } from 'react';
import { overlayColorScheme } from '../../utils/colorScheme';
import { AutomatedInspectionMetrics, AutomatedInspectionTableData, automatedListOrder, StatsDisplayNames } from '../../utils/inspection';



export default function AutomatedMeasurementList(
    { measurementList,
        boxHeight,
        setHighlightedMeasurement,
        toggleDisplay,
        toggleGroup,
        toggleAll,
        loadingStatus
    }: {
        measurementList: AutomatedInspectionTableData[],
        boxHeight: number,
        setHighlightedMeasurement: (arg: { metric: AutomatedInspectionMetrics, stat?: StatsDisplayNames } | null) => void,
        toggleDisplay: (internal_name: string) => void,
        toggleGroup: (id: number) => void,
        toggleAll: (e: boolean) => void,
        loadingStatus: boolean
    }
) {
    const [openGroups, setOpenGroups] = useState<number[]>([6, 7, 8]); // TODO: hardcoded magic numbers here. Must match what is in groupingId function.
    const numSelected = measurementList.filter(measurement => measurement.display).length
    const rowCount = measurementList.length
    const handleSelectAllClick = () => {
        if (numSelected >= 0 && numSelected < rowCount) toggleAll(true)
        else toggleAll(false)
    }
    const cellSx = { borderTop: 0, borderLeft: 0, borderRight: 0, pl: 0.5, pr: 0.5, pb: .2, pt: .2, m: 0 }
    const cellSxHelp = { borderTop: 0, borderLeft: 0, borderRight: 0, pl: 0, pr: 0, pb: .2, pt: .2, m: 0 }
    const sortedMeasurementList = measurementList.sort((a, b) => {
        return automatedListOrder.indexOf(a.internal_name) - automatedListOrder.indexOf(b.internal_name);
    });

    const booleanMetrics = [
        AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED,
        AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG
    ] as string[]

    const noCheckboxMetrics = [
        AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
        AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY
    ] as string[]
    const rowValue = (row: AutomatedInspectionTableData) => {
        if (booleanMetrics.includes(row.internal_name)) {
            return row.result === 0 ?
                <span style={{ color: overlayColorScheme.green }} >false</span> :
                <span style={{ color: overlayColorScheme.orange }}>true</span>
        } else {
            return row.result.toPrecision(3) + row.display_units
        }
    }

    const rowCheckbox = (row: AutomatedInspectionTableData) => {
        if (booleanMetrics.includes(row.internal_name)) { return null }
        if (row.display_name.includes(StatsDisplayNames.STD_DEV)) { return null }
        if (noCheckboxMetrics.includes(row.internal_name)) { return null }
        else {
            return <Checkbox
                data-id={`${row.display_name} Checkbox`}
                sx={{ p: .1, [`&, &.${checkboxClasses.checked}`]: { color: row.color.slice(0, -2), } }}
                size='small'
                checked={row.display}
                onChange={() => toggleDisplay(row.internal_name)}
            />
        }
    }

    const groupedRows = () => {
        const filteredMeasurementList = sortedMeasurementList.filter((row) => row.group_id !== 0)
        const uniqueGroupIds = Array.from(new Set(filteredMeasurementList.map((row) => row.group_id)))
        const groupedMeasurementList = uniqueGroupIds.map((id) => {
            return filteredMeasurementList.filter((row) => row.group_id === id)
        })
        return groupedMeasurementList.map((group) => {
            return (
                <React.Fragment key={group[0].group_id}>
                    <TableRow>
                        <TableCell sx={cellSx} style={{ backgroundColor: "#ffffff15" }}>
                            <Checkbox
                                color="primary"
                                sx={{ p: .1 }}
                                indeterminate={group.filter((row) => row.display).length > 0 && group.filter((row) => row.display).length < group.length}
                                checked={group.filter((row) => row.display).length === group.length}
                                size='small'
                                onClick={() => {
                                    toggleGroup(group[0].group_id)
                                    if (!openGroups.includes(group[0].group_id)) setOpenGroups([...openGroups, group[0].group_id])
                                }}
                            />
                        </TableCell>
                        <TableCell sx={cellSx} colSpan={3} style={{ backgroundColor: "#ffffff15" }} >
                            <Box alignContent={'center'}>
                                <IconButton
                                    size="small"
                                    sx={{ pl: 0, pt: 0, pb: 0, m: 0 }}
                                    onClick={() => {
                                        openGroups.includes(group[0].group_id) ? setOpenGroups(openGroups.filter((e) => e !== group[0].group_id)) :
                                            setOpenGroups([...openGroups, group[0].group_id])
                                    }}
                                >
                                    {openGroups.includes(group[0].group_id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                                {group[0].group_name}
                            </Box>
                        </TableCell>
                    </TableRow>
                    {group
                        .filter((row) => openGroups.includes(row.group_id))
                        .map((row) => (
                            <TableRow
                                hover
                                key={row.internal_name.concat(row.statistic?.toString() || '')}
                                onMouseEnter={() => setHighlightedMeasurement({ metric: row.internal_name, stat: row?.statistic || undefined })}
                                onMouseLeave={() => setHighlightedMeasurement(null)}
                            >
                                <TableCell sx={cellSx}>
                                    {rowCheckbox(row)}
                                </TableCell>
                                <TableCell sx={{ borderTop: 0, borderLeft: 0, borderRight: 0, pl: 0.5, pr: 0.5, pb: .2, pt: .2, m: 0 }}>
                                    {row.display_name}
                                </TableCell>
                                <TableCell sx={cellSxHelp}>
                                    <Tooltip title={row.description}>
                                        <HelpIcon fontSize='small' style={{ transform: "scale(0.8)" }} sx={{ fillOpacity: 0.5, p: 0, mb: -0.5 }} />
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={cellSx}>
                                    {rowValue(row)}
                                </TableCell>
                            </TableRow>
                        ))}
                </React.Fragment>
            )
        })
    }

    const loadingGate = () => {
        if (loadingStatus) {
            return <Typography variant='body2' sx={{ ml: 1, mt: 1, alignSelf: 'center', textAlign: 'center', opacity: 0.7 }}>
                <i>Checking for automated measurements...</i>
            </Typography>
        } else if (measurementList.length === 0) {
            return <Typography variant='body2' sx={{ ml: 1, mt: 1, alignSelf: 'center', textAlign: 'center', opacity: 0.7 }}>
                <i>There are no automated measurements or detections for this image.</i>
            </Typography>
        } else {
            return <>
                <TableContainer sx={{ width: '100%' }}>
                    <Table stickyHeader size='small' >
                        <TableHead>
                            <TableRow >
                                <TableCell sx={cellSx}>
                                    {
                                        measurementList.length !== 0 &&
                                        <Checkbox
                                            color="primary"
                                            sx={{ p: .1 }}
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            size='small'
                                            onClick={handleSelectAllClick}
                                        />
                                    }
                                </TableCell>
                                <TableCell sx={cellSx}>Item</TableCell>
                                <TableCell sx={cellSx}></TableCell>
                                <TableCell sx={cellSx}>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* length checks might be needed to kill the repeating rows bug? */}
                            {measurementList.length > 0 && groupedRows()}
                        </TableBody>
                    </Table>
                </TableContainer >
            </>
        }
    }
    return <>
        <Stack sx={{ height: boxHeight, width: '100%', ml: 0.5, mr: 0.5 }} >
            <Typography variant='body2' sx={{ ml: 1, mt: 1, alignSelf: 'center' }}>
                Automated Inspection
            </Typography>
            {loadingGate()}
        </Stack >
    </>
}
